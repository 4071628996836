import React from "react";
import styled, { keyframes } from "styled-components";
import { colors } from "../colors/colors";
import { TiArrowUp } from "react-icons/ti";

const enter = keyframes`
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const Container = styled.div`
  width: 200px;
  position: fixed;
  z-index: 98;
  bottom: 30px;
  right: 30px;
  background: black;
  display: flex;
  justify-content: center;
  border-radius: 3px;
  padding: 10px;
  cursor: pointer;
  opacity: 0;
  transform: translateY(10px);

  animation: ${enter} 0.4s ease-out forwards;

  @media (max-width: 500px) {
    bottom: 20px;
    right: 10px;
  }
`;

const Text = styled.p`
  margin: 0;
  font-family: "Tanker";
  color: ${colors.red};
`;

const BackToTop = (props) => (
  <Container onClick={() => props.handleClickEvent()}>
    <Text>
      Back to Top <TiArrowUp style={{ marginBottom: -5 }} size={24} />
    </Text>
  </Container>
);

export default BackToTop;
