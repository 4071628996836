import React from "react";
import styled, { keyframes } from "styled-components";

const fade = keyframes`
    from {
        opacity: 0.6;
    }

    to {
        opacity: 1;
    }
`;
const Container = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;
const Item = styled.div`
  height: 150px;
  width: auto;
  background-color: #1f1f1f;
  flex-basis: 23%;
  margin: 1px;
  animation: ${fade} 0.7s ease-in-out alternate infinite;

  @media (max-width: 1375px) {
    flex-basis: 19%;
  }

  @media (max-width: 1100px) {
    flex-basis: 24%;
  }

  @media (max-width: 830px) {
    flex-basis: 32%;
  }
`;
const LoadingContainer = (props) => (
  <Container>
    <Item />
    <Item />
    <Item />
    <Item />
    <Item />
    <Item />
    <Item />
    <Item />
    <Item />
    <Item />
    <Item />
    <Item />
    <Item />
    <Item />
    <Item />
    <Item />
    <Item />
    <Item />
    <Item />
    <Item />
    <Item />
    <Item />
    <Item />
    <Item />
  </Container>
);

export default LoadingContainer;
