import React, { Component } from "react";
import Layout from "../components/layout";
import "../styles/global.css";
import "./media.css";
import { graphql } from "gatsby";

//Components
import SectionTitle from "../components/section-title";
import ResponsiveFrame from "../components/responsive-frame";
import GalleryCard from "../components/gallery-card";
import SEO from "../components/seo";
import LoadingContainer from "../components/loading-container";
import Img from "gatsby-image";

//NPM
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Loader from "react-loader-spinner";
import styled from "styled-components";
import "react-alice-carousel/lib/alice-carousel.css";
import Fade from "react-reveal/Fade";
import FsLightbox from "fslightbox-react";
import BackToTop from "../components/back-to-top";
import { GalleryContainer } from ".";
import { Flex, Space } from "@mantine/core";
import { colors } from "../colors/colors";

const Grid = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  max-width: 1080px;
  margin: 0 auto;
  gap: 5px;
`;
const Container = styled.div`
  /* flex-basis: 16.6%; */
  flex-basis: 23%;
  height: 180px;
  text-align: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  /*Media Queries*/
  @media (max-width: 1000px) {
    flex-basis: 25%;
  }

  @media (max-width: 850px) {
    flex-basis: 33.33%;
  }
  @media (max-width: 600px) {
    flex-basis: 49%;
  }
`;
const ImgWrapper = styled.div`
  height: 100%;
  transition: 0.3s ease-out;
  transform: scale(1);

  ${Container}:hover & {
    transform: scale(1.1);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s ease-out;
    transform: scale(1);
  }
`;

const CenterBlock = styled.div`
  text-align: center;
  display: block;
  margin: 0 auto;
  padding-top: 70px;
`;

const AlbumTitle = styled.h1`
  text-align: center;
  font-family: "Tanker";
  font-size: 32px;
  margin: 60px 0;
  padding-top: 70px;

  font-weight: 400;

  @media (max-width: 600px) {
    font-size: 26px;
  }
`;
class MediaPage extends Component {
  constructor(props) {
    super(props);
    this.titleRef = React.createRef();
    this.galleryRef = React.createRef();
    this.albumsRef = React.createRef();

    this.state = {
      pictures: [],
      gallery: [],
      currentAlbum: "",
      albums: [],
      loading: false,
      activeGallery: null,
      isOpen: true,
      hasVideo: false,
      showScrollToTop: false,
      activeView: "photos",
    };
  }

  componentDidMount() {
    const data = this.props.data.allWordpressPost.edges;
    const sortedData = data.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.node.acf.event_date) - new Date(a.node.acf.event_date);
    });

    this.setState({ albums: sortedData });
    this.checkIfAlbumSelected();

    if (typeof window !== "undefined") {
      window.addEventListener("scroll", this.handleScroll);
    }
  }

  handleScroll = (event) => {
    const scrollTop = window.pageYOffset;
    const { showScrollToTop } = this.state;

    if (scrollTop > 1200) {
      if (showScrollToTop) {
        //Do NOthing, return
        return;
      }
      this.setState({ showScrollToTop: true });
    } else {
      if (showScrollToTop === false) {
        return;
      }
      this.setState({ showScrollToTop: false });
    }
  };

  handleScrollToTop = () => {
    this.albumsRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  checkIfAlbumSelected = () => {
    //Check if they came from the homepage
    try {
      const Atag = this.props.location.state.tag;
      if (Atag === undefined) {
        return;
      } else {
        const { name } = this.props.location.state;
        let albums = this.props.data.allWordpressPost.edges;
        let album = albums.filter((alb) => alb.node.acf.event_name === name);
        let video = album[0].node.acf.youtube_video;
        this.loadAlbumWP(album[0].node.acf.gallery, name, video);
        if (typeof window !== "undefined") {
          window.scrollTo({
            top: 250,
            left: 0,
            behavior: "smooth",
          });
        }
      }
    } catch {
      console.log("No tag");
    }
  };

  loadAlbumWP = (gallery, name, video) => {
    let videoUrl = "";
    let hasVideo;
    if (video === "") {
      hasVideo = false;
    } else {
      hasVideo = true;
      videoUrl = video;
    }
    this.setState(
      {
        loading: true,
        currentAlbum: name,
        hasVideo: hasVideo,
        videoUrl: videoUrl,
      },
      () => {
        this.galleryRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    );

    setTimeout(() => {
      this.setState(
        {
          activeGallery: gallery,
          imageUrls: gallery.map((object) => object.source_url),
          loading: false,
        },
        () => {
          //Scroll to gallery
          this.titleRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      );
    }, 1000);
  };
  openLightbox = (i) => {
    this.setState({ isOpen: !this.state.isOpen, photoIndex: i });
  };

  render() {
    const {
      albums,
      activeGallery,
      photoIndex,
      imageUrls,
      isOpen,
      loading,
      videoUrl,
      showScrollToTop,
      activeView,
    } = this.state;

    const videoAlbums = albums.filter((album) => album.node.acf.youtube_video);

    return (
      <Layout>
        <SEO
          title="Media"
          keywords={[
            `media`,
            `yup`,
            `life`,
            `FOC`,
            `Festival`,
            `Colour`,
            `Backyard`,
            `Melee`,
            `Events`,
            `Fete`,
            `Trinidad`,
            `Carnival`,
            `Party`,
            `Fete`,
          ]}
        />

        <SectionTitle
          title="Event photos and videos"
          subtitle="Experience the madness that is YUP!"
        />
        <Flex
          style={{
            width: 240,
            borderRadius: 120,
            margin: "0 auto",
            border: "1px solid #E2E2E2",
            padding: 8,
          }}
          direction="row"
          align="center"
          justify="space-between"
        >
          <ToggleButton
            active={this.state?.activeView === "photos"}
            onClick={() => this.setState({ activeView: "photos" })}
          >
            Photos
          </ToggleButton>
          <ToggleButton
            active={this.state?.activeView === "videos"}
            onClick={() => this.setState({ activeView: "videos" })}
          >
            Videos
          </ToggleButton>
        </Flex>
        <Space h={40} />
        {showScrollToTop && (
          <BackToTop handleClickEvent={this.handleScrollToTop} />
        )}

        <GalleryContainer video={activeView === "videos"} ref={this.albumsRef}>
          <>
            {activeView === "photos"
              ? albums.map((album, index) => (
                  <Fade key={index} bottom distance={"10px"}>
                    <div
                      onClick={() =>
                        this.loadAlbumWP(
                          album.node.acf.gallery,
                          album.node.acf.event_name,
                          album.node.acf.youtube_video
                        )
                      }
                    >
                      <GalleryCard
                        title={album.node.acf.event_name}
                        alt={album.node.acf.cover.alt_text}
                        url={album.node.acf.cover.source_url}
                        tag={album.node.acf.tag}
                        count={album.node.acf.image_count}
                        isVideoCover={activeView === "videos"}
                      />
                    </div>
                  </Fade>
                ))
              : null}

            {activeView === "videos"
              ? videoAlbums.map((album, index) => (
                  <Fade key={index} bottom distance={"10px"}>
                    <div
                      style={{
                        // display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <EmbedContainer>
                        <Frame
                          src={album.node.acf.youtube_video}
                          frameBorder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />
                      </EmbedContainer>
                      <Title>{album.node.acf.event_name}</Title>
                    </div>
                  </Fade>
                ))
              : null}
          </>
        </GalleryContainer>

        {loading ? (
          <>
            <CenterBlock ref={this.galleryRef}>
              <Loader type="ThreeDots" color="#B4271D" height="50" width="50" />
            </CenterBlock>
            <LoadingContainer />
          </>
        ) : (
          <>
            {this.state.currentAlbum !== "" ? (
              <AlbumTitle ref={this.titleRef} id="title">
                <Fade>
                  <SectionTitle
                    title={this.state.currentAlbum}
                    subtitle="You're current viewing:"
                    margin
                  />
                </Fade>
              </AlbumTitle>
            ) : null}
            {/* Don't show image gallery when we're on video mode */}
            {activeView === "photos" && (
              <Grid>
                {activeGallery &&
                  !this.state.loadingGallery &&
                  activeGallery.map((photo, index) => (
                    <Container
                      key={index}
                      onClick={() => this.openLightbox(index)}
                    >
                      <ImgWrapper>
                        <Img
                          style={{
                            width: "100%",
                            height: "100%",
                            margin: 0,
                            objectFit: "cover",
                            transform: "scale(1)",
                            ":hover": {
                              transform: "scale(1.)",
                            },
                          }}
                          fadeIn
                          objectFit="cover"
                          fixed={photo.localFile.childImageSharp.fixed}
                        />
                      </ImgWrapper>
                    </Container>
                  ))}
              </Grid>
            )}

            {imageUrls && (
              <FsLightbox
                key={imageUrls}
                toggler={isOpen}
                slide={photoIndex + 1}
                sources={imageUrls}
                type="image"
              />
            )}
          </>
        )}
      </Layout>
    );
  }
}

export default MediaPage;

export const mediaQuery = graphql`
  query ($category: String = "Album") {
    allWordpressPost(
      filter: { categories: { elemMatch: { name: { eq: $category } } } }
    ) {
      edges {
        node {
          title
          acf {
            event_name
            image_count
            youtube_video
            cover {
              source_url
              alt_text
            }
            gallery {
              source_url
              localFile {
                childImageSharp {
                  fixed {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
              media_details {
                height
                width
              }
            }
            tag
            upload_date
            event_date
            hide
          }
        }
      }
    }
  }
`;

const ToggleButton = styled.button`
  outline: none;
  border: none;
  padding: 12px 20px;
  font-family: "Poppins";
  font-weight: 500;
  cursor: pointer;
  font-size: 20px;
  border-radius: 120px;
  background: ${(props) => (props.active ? "#fb045d" : "transparent")};
  transition: all 0.2s ease-in-out;
  color: ${(props) => (props.active ? "#fff" : "#000")};
`;

const EmbedContainer = styled.div`
  position: relative;
  overflow: hidden;
  max-width: 500px;
  margin: 0 auto;
  aspect-ratio: 16/9;
  /* margin-bottom: 20px; */
`;

const Frame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Title = styled.h1`
  color: ${colors.copy};
  font-size: 17px;
  font-family: "Tanker";
  transition: 0.4s ease-in-out;
  font-weight: 400;
  pointer-events: none;
  text-align: center;
  transition: 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  margin-top: 8px;
  width: auto;
  margin-bottom: 0;
`;
